import React, { Fragment } from 'react';
import clsx from 'clsx';

import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Link from '@common/Link';
import Video from '@common/Video';
import Embedded from '@common/Embedded';

import blueCircleQuote from '@images/blueCircleQuote.svg';

const RichText = ({ 
  json,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  ul,
  li,
  b,
  redUnderline}) => {
  return (
    <Fragment>
      {json && documentToReactComponents(JSON.parse(json.raw), {
        renderText: text =>
          text
            .split('\n')
            .flatMap((text, i) => [i > 0 && <br key={i} />, text]),
        renderMark: {
          [MARKS.BOLD]: text => {
            // One occasion on homepage
            if (text[1] === 'Lillio') {
              return <span className={clsx('fs-56 fw-semibold', b)}>{text}</span>
            }
            return <span className={clsx('fw-medium', b)}>{text}</span>
          },
          [MARKS.ITALIC]: text => {
            // Using italics to trigger the coming-soon class to the specifc text
            if (text[1] === 'NEW') {
              return <span className='coming-soon'>{text}</span>;
            }
            return <i>{text}</i>
          },
          [MARKS.UNDERLINE]: text  => {
            if (redUnderline) {
              return <span className='text-red'>{text}</span>
            }
            return <u>{text}</u>
          }
        },
        renderNode: {
          [BLOCKS.HEADING_1]: (node, children) =>
            <h1 className={h1}>{children}</h1>
          ,
          [BLOCKS.HEADING_2]: (node, children) => {
            // One occasion on homepage
            if (children[0][1] === 'Centers love ') {
              return <h2 className={clsx(`fs-44`, h2)}>{children}</h2>
            }
            return <h2 className={h2}>{children}</h2>
          }
          ,
          [BLOCKS.HEADING_3]: (node, children) =>
            <h3 className={h3}>{children}</h3>
          ,
          [BLOCKS.HEADING_4]: (node, children) =>
            <h4 className={h4}>{children}</h4>
          ,
          [BLOCKS.HEADING_5]: (node, children) =>
            <h5 className={h5}>{children}</h5>
          ,
          [BLOCKS.HEADING_6]: (node, children) =>
            <h6 className={h6}>{children}</h6>
          ,
          [BLOCKS.PARAGRAPH]: (node, children) => {
            if (node.content.every(x => x.nodeType === 'text' && x.value === '')) {
              // Fix for blank paragraph blocks adding margin
              return <>{children}</>;
            }
            return (
              <p className={p}>{children}</p>
            )
          },
          [BLOCKS.UL_LIST]: (node, children) =>
            <ul className={ul}>{children}</ul>
          ,
          [BLOCKS.LIST_ITEM]: (node, children) =>
            <li className={li}>
              {children}
            </li>
          ,
          [BLOCKS.TABLE]: (node, children) => 
            <table className='mb-3'>{children}</table>
          ,
          [BLOCKS.TABLE_HEADER_CELL]: (node, children) =>
            <th className='p-1'>{children}</th>
          ,
          [BLOCKS.TABLE_CELL]: (node, children) =>
            <td className='p-1'>{children}</td>
          ,
          [BLOCKS.QUOTE]: (node, children) =>
            <blockquote className='position-relative my-5'>
              <img height={97} width={97} style={{position: 'absolute', top: '-50px', left: 0, width: '100%'}} src={blueCircleQuote}/>
              {children}
            </blockquote>
          ,
          [BLOCKS.EMBEDDED_ASSET]: (node) => {
            const asset = json.references?.find(x => x.contentful_id === node.data?.target?.sys?.id);
            return <Embedded data={asset}/>
          },
          [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
            const entry = json.references?.find(x => x.contentful_id === node.data?.target?.sys?.id);
            return <Embedded data={entry}/>
          },
          [INLINES.EMBEDDED_ENTRY]: (node, children) => {
            const entry = json.references?.find(x => x.contentful_id === node.data?.target?.sys?.id);
            if (entry?.__typename === 'ContentfulButton') {
              return <Embedded data={entry}/>
            }
            if (entry?.__typename === 'ContentfulVideo') {
              return (
                <Video videoId={entry.videoId} image={entry.image} />
              )
            }
          },
          [INLINES.HYPERLINK]: (node, children) =>
            <Link to={node.data.uri} className={a}>
              {children}
            </Link>
          },
        })
      }
    </Fragment>
  )
}

export default RichText
