import React, { useLayoutEffect, useState } from 'react';
import { isBefore, isAfter } from 'date-fns';
import Cookies from 'js-cookie';
import { isBrowser } from '@helpers/utils';
import { useLocation } from '@gatsbyjs/reach-router';

import Modal from '@common/Modal';

const Popups = ({ data }) => {
  const location = useLocation();
  const today = new Date();
  const { popups } = data?.contentfulLayout;
  const [activePopups, setActivePopups] = useState([]);
  const [show, setShow] = useState([]);
  const handleClose = id => {
    Cookies.set('_hm-popup-' + id, true, { expires: 1 });
    setShow([])
  };
  const handleShow = index => {
    const list = new Array(popups.length).fill(false);
    list[index] = true;
    setShow([...list]);
  };
  const triggerPopup = (id, offset, i) => {
    const cookie = Cookies.get('_hm-popup-' + id);
    if (window.pageYOffset > offset && !cookie) {
      handleShow(i);
    }
  };
  const filterByDate = list => list.filter(x => isAfter(today, new Date(x.start)) && (isBefore(today, new Date(x.end)) || x.end == null));
  const filterByUrl = list => list.filter(x => x.rules?.some(y => location.pathname.startsWith(y)) === x.showRule || x.rules == null);
  const addTriggerEvents = list => {
    list.forEach((item, i) => {
      const cookie = Cookies.get('_hm-popup-' + item.id)
      if (item.trigger === 'Timer' && item.seconds > 0 && !cookie) {
        setTimeout(() => handleShow(i), (item.seconds * 1000));
      }
      else if (item.trigger === 'On Scroll' && item.offset > 0 && !cookie && isBrowser()) {
        const height = (document.height !== undefined) ? document.height : document.body.offsetHeight;
        const offset = (item.offset / 100) * height;
        window.addEventListener('scroll', () => triggerPopup(item.id, offset, i));
      }
    });
  }
  useLayoutEffect(() => {
    // Remove all existing popup cookies
    popups.forEach(popup => Cookies.remove('_hm-popup-' + popup.id));
  }, [popups]);
  useLayoutEffect(() => {
    const newList = filterByUrl(filterByDate(popups));
    addTriggerEvents(newList)
    setActivePopups(newList)
  }, [popups, location]);
  return (
    <>
      {activePopups && activePopups.map((popup, i) => (
        <Modal key={popup.id} data={popup.modal} show={show[i]} close={() => handleClose(popup.id)}/>
      ))}
    </>
  )
}

export default Popups
