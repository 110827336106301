import React from 'react';

const PlayButton = ({ color }) => {
  return (
    <svg id="Group_3511" data-name="Group 3511" xmlns="http://www.w3.org/2000/svg" width="15.634" height="15.634" viewBox="0 0 15.634 15.634">
      <g id="Path_8019" data-name="Path 8019" transform="translate(0 0)" className={`fill-${color}`}>
        <path d="M 7.817201614379883 15.13440132141113 C 3.782481670379639 15.13440132141113 0.5000016093254089 11.85192203521729 0.5000016093254089 7.817201614379883 C 0.5000016093254089 3.782481670379639 3.782481670379639 0.5000016093254089 7.817201614379883 0.5000016093254089 C 11.85192203521729 0.5000016093254089 15.13440132141113 3.782481670379639 15.13440132141113 7.817201614379883 C 15.13440132141113 11.85192203521729 11.85192203521729 15.13440132141113 7.817201614379883 15.13440132141113 Z" stroke="none"/>
        <path d="M 7.817201614379883 1.000001907348633 C 4.058181762695312 1.000001907348633 1.000001907348633 4.058181762695312 1.000001907348633 7.817201614379883 C 1.000001907348633 11.57622146606445 4.058181762695312 14.63440132141113 7.817201614379883 14.63440132141113 C 11.57622146606445 14.63440132141113 14.63440132141113 11.57622146606445 14.63440132141113 7.817201614379883 C 14.63440132141113 4.058181762695312 11.57622146606445 1.000001907348633 7.817201614379883 1.000001907348633 M 7.817201614379883 1.9073486328125e-06 C 12.134521484375 1.9073486328125e-06 15.63440132141113 3.499881744384766 15.63440132141113 7.817201614379883 C 15.63440132141113 12.134521484375 12.134521484375 15.63440132141113 7.817201614379883 15.63440132141113 C 3.499881744384766 15.63440132141113 1.9073486328125e-06 12.134521484375 1.9073486328125e-06 7.817201614379883 C 1.9073486328125e-06 3.499881744384766 3.499881744384766 1.9073486328125e-06 7.817201614379883 1.9073486328125e-06 Z" stroke="none" fill="#000"/>
      </g>
      <path id="Polygon_2" data-name="Polygon 2" d="M2.736,1.519a1,1,0,0,1,1.736,0L6.353,4.811a1,1,0,0,1-.868,1.5H1.723a1,1,0,0,1-.868-1.5Z" transform="translate(11.872 4.213) rotate(90)"/>
    </svg>
  )
}

export default PlayButton
